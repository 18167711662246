<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-07 16:46:29
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="targetb">
  </div>
</template>

<script>
export default {
  name: 'targetb',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.targetb {
}
</style>
